import React, { ReactElement } from "react";

export default function FreeCreditEvaluationLogo(): ReactElement {
    return (
        <svg
            width="262"
            height="27"
            viewBox="0 0 262 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M34.2918 22.2808H31.8418V4.17578H42.9148V6.55178H34.2918V11.9168H41.8128V14.2928H34.2918V22.2808Z"
                fill="#0E5AA9"
            />
            <path
                d="M45.855 22.2787H43.502V9.14769H45.731V11.0837C46.499 9.56469 47.756 8.80469 49.504 8.80469C49.978 8.80469 50.491 8.86969 51.047 9.00069L50.826 11.2787C50.352 11.1317 49.895 11.0577 49.455 11.0577C48.377 11.0577 47.507 11.4257 46.846 12.1607C46.184 12.8957 45.854 13.9497 45.854 15.3217V22.2787H45.855Z"
                fill="#0E5AA9"
            />
            <path
                d="M63.152 15.47C63.152 15.633 63.128 16.033 63.078 16.67H52.617C52.83 17.83 53.319 18.744 54.088 19.414C54.855 20.084 55.9 20.418 57.224 20.418C58.007 20.418 58.816 20.283 59.649 20.014C60.482 19.744 61.201 19.389 61.805 18.948L62.81 20.737C62.123 21.26 61.274 21.696 60.261 22.048C59.248 22.4 58.22 22.575 57.175 22.575C55.982 22.575 54.924 22.379 54.002 21.987C53.079 21.595 52.348 21.065 51.809 20.395C51.271 19.725 50.862 18.99 50.584 18.19C50.306 17.39 50.168 16.548 50.168 15.667C50.168 13.707 50.78 12.074 52.005 10.767C53.246 9.47703 54.847 8.83203 56.807 8.83203C58.669 8.83203 60.188 9.42803 61.364 10.62C62.555 11.812 63.152 13.429 63.152 15.47ZM52.617 14.637H60.726C60.661 13.51 60.264 12.608 59.538 11.93C58.811 11.252 57.9 10.913 56.807 10.913C55.745 10.913 54.83 11.26 54.063 11.955C53.296 12.65 52.813 13.543 52.617 14.637Z"
                fill="#0E5AA9"
            />
            <path
                d="M76.65 15.47C76.65 15.633 76.626 16.033 76.576 16.67H66.115C66.328 17.83 66.817 18.744 67.586 19.414C68.353 20.084 69.398 20.418 70.722 20.418C71.505 20.418 72.314 20.283 73.147 20.014C73.98 19.745 74.699 19.389 75.303 18.948L76.308 20.737C75.621 21.26 74.772 21.696 73.759 22.048C72.746 22.4 71.718 22.575 70.673 22.575C69.48 22.575 68.422 22.379 67.5 21.987C66.577 21.595 65.846 21.065 65.307 20.395C64.769 19.725 64.36 18.99 64.082 18.19C63.804 17.39 63.666 16.548 63.666 15.667C63.666 13.707 64.278 12.074 65.503 10.767C66.744 9.47703 68.345 8.83203 70.305 8.83203C72.167 8.83203 73.686 9.42803 74.862 10.62C76.053 11.812 76.65 13.429 76.65 15.47ZM66.115 14.637H74.224C74.159 13.51 73.762 12.608 73.036 11.93C72.31 11.252 71.398 10.913 70.305 10.913C69.243 10.913 68.328 11.26 67.561 11.955C66.794 12.65 66.311 13.543 66.115 14.637Z"
                fill="#0E5AA9"
            />
            <path
                d="M89.1527 22.5485C86.5397 22.5485 84.3347 21.6825 82.5387 19.9515C80.7417 18.2045 79.8438 15.9825 79.8438 13.2875C79.8438 10.5435 80.7577 8.28947 82.5877 6.52547C84.4167 4.74547 86.6297 3.85547 89.2267 3.85547C90.8757 3.85547 92.3957 4.22347 93.7837 4.95847C95.1727 5.66147 96.2587 6.61647 97.0427 7.82447L95.2308 9.34347C93.6948 7.26947 91.7097 6.23247 89.2767 6.23247C87.3497 6.23247 85.7157 6.90247 84.3777 8.24147C83.0387 9.58047 82.3688 11.2715 82.3688 13.3125C82.3688 15.3055 83.0218 16.9555 84.3288 18.2615C85.6358 19.5525 87.2767 20.1975 89.2527 20.1975C90.4617 20.1975 91.5888 19.9275 92.6338 19.3885C93.6788 18.8495 94.5768 18.0985 95.3288 17.1345L97.0928 18.6295C96.2278 19.8385 95.0967 20.7945 93.6997 21.4955C92.3007 22.1975 90.7857 22.5485 89.1527 22.5485Z"
                fill="#0E5AA9"
            />
            <path
                d="M100.422 22.2787H98.0693V9.14769H100.298V11.0837C101.066 9.56469 102.323 8.80469 104.071 8.80469C104.545 8.80469 105.058 8.86969 105.614 9.00069L105.393 11.2787C104.919 11.1317 104.462 11.0577 104.022 11.0577C102.944 11.0577 102.074 11.4257 101.413 12.1607C100.751 12.8957 100.421 13.9497 100.421 15.3217V22.2787H100.422Z"
                fill="#0E5AA9"
            />
            <path
                d="M117.717 15.47C117.717 15.633 117.693 16.033 117.643 16.67H107.182C107.395 17.83 107.884 18.744 108.653 19.414C109.42 20.084 110.465 20.418 111.789 20.418C112.572 20.418 113.381 20.283 114.214 20.014C115.047 19.745 115.766 19.389 116.37 18.948L117.375 20.737C116.688 21.26 115.839 21.696 114.826 22.048C113.813 22.4 112.785 22.575 111.74 22.575C110.547 22.575 109.489 22.379 108.567 21.987C107.644 21.595 106.913 21.065 106.374 20.395C105.836 19.725 105.427 18.99 105.149 18.19C104.871 17.39 104.733 16.548 104.733 15.667C104.733 13.707 105.345 12.074 106.57 10.767C107.811 9.47703 109.412 8.83203 111.372 8.83203C113.234 8.83203 114.753 9.42803 115.929 10.62C117.121 11.812 117.717 13.429 117.717 15.47ZM107.182 14.637H115.291C115.226 13.51 114.829 12.608 114.103 11.93C113.377 11.252 112.465 10.913 111.372 10.913C110.31 10.913 109.395 11.26 108.628 11.955C107.861 12.65 107.379 13.543 107.182 14.637Z"
                fill="#0E5AA9"
            />
            <path
                d="M129.38 11.0594V3.73438H131.733V22.2804H129.38V20.4434C128.236 21.8814 126.718 22.5994 124.823 22.5994C122.912 22.5994 121.328 21.9384 120.07 20.6154C118.829 19.2764 118.209 17.6354 118.209 15.6914C118.209 13.7644 118.829 12.1474 120.07 10.8404C121.311 9.50137 122.88 8.83137 124.774 8.83137C126.652 8.83037 128.187 9.57337 129.38 11.0594ZM129.551 15.6904C129.551 14.3674 129.134 13.2574 128.301 12.3584C127.485 11.4434 126.406 10.9864 125.068 10.9864C123.728 10.9864 122.642 11.4444 121.809 12.3584C120.993 13.2734 120.584 14.3834 120.584 15.6904C120.584 17.0294 120.992 18.1564 121.809 19.0714C122.642 19.9864 123.728 20.4434 125.068 20.4434C126.407 20.4434 127.485 19.9864 128.301 19.0714C129.134 18.1724 129.551 17.0464 129.551 15.6904Z"
                fill="#0E5AA9"
            />
            <path
                d="M135.112 7.28803C134.622 7.28803 134.214 7.12803 133.886 6.81003C133.56 6.49203 133.397 6.08703 133.397 5.59803C133.397 5.12403 133.56 4.73203 133.886 4.42203C134.213 4.11203 134.621 3.95703 135.112 3.95703C135.569 3.95703 135.961 4.11603 136.288 4.43503C136.614 4.75403 136.778 5.14203 136.778 5.59903C136.778 6.08903 136.614 6.49303 136.288 6.81103C135.961 7.12803 135.569 7.28803 135.112 7.28803ZM136.239 9.15003V22.281H133.886V9.15003H136.239Z"
                fill="#0E5AA9"
            />
            <path
                d="M146.113 19.6822L146.995 21.4942C146.048 22.2292 144.88 22.5972 143.491 22.5972C142.12 22.5972 141.078 22.1732 140.368 21.3232C139.657 20.4742 139.303 19.2572 139.303 17.6732V11.1562H136.975V9.14719H139.328V4.49219H141.631V9.14719H146.285V11.1562H141.631V17.8442C141.631 19.5752 142.341 20.4412 143.762 20.4412C144.528 20.4422 145.312 20.1892 146.113 19.6822Z"
                fill="#0E5AA9"
            />
            <path
                d="M161.726 22.2808H150.456V4.17578H161.726V6.50278H152.907V11.9168H161.065V14.2448H152.907V19.9528H161.726V22.2808Z"
                fill="#0E5AA9"
            />
            <path
                d="M168.195 18.9724L172.164 9.14844H174.761L168.979 22.2554H167.338L161.556 9.14844H164.177L168.195 18.9724Z"
                fill="#0E5AA9"
            />
            <path
                d="M185.245 11.0581V9.14712H187.499V22.2781H185.245V20.4161C184.069 21.8701 182.526 22.5961 180.615 22.5961C178.72 22.5961 177.152 21.9271 175.911 20.5871C174.67 19.2481 174.05 17.5901 174.05 15.6141C174.05 13.6381 174.67 12.0211 175.911 10.7631C177.152 9.47313 178.721 8.82812 180.615 8.82812C182.476 8.82913 184.02 9.57213 185.245 11.0581ZM185.392 15.6891C185.392 14.3661 184.975 13.2561 184.142 12.3571C183.309 11.4421 182.223 10.9851 180.884 10.9851C179.544 10.9851 178.466 11.4431 177.65 12.3571C176.834 13.2711 176.425 14.3901 176.425 15.7131C176.425 17.0361 176.833 18.1551 177.65 19.0691C178.466 19.9841 179.545 20.4411 180.884 20.4411C182.223 20.4411 183.309 19.9841 184.142 19.0691C184.975 18.1711 185.392 17.0451 185.392 15.6891Z"
                fill="#0E5AA9"
            />
            <path
                d="M192.032 3.73438V22.2804H189.679V3.73438H192.032Z"
                fill="#0E5AA9"
            />
            <path
                d="M199.675 22.5974C197.96 22.5974 196.592 22.0914 195.571 21.0784C194.55 20.0664 194.04 18.7024 194.04 16.9874V9.14844H196.392V16.9144C196.392 17.9924 196.682 18.8464 197.262 19.4744C197.841 20.1034 198.646 20.4174 199.675 20.4174C200.703 20.4174 201.512 20.1034 202.1 19.4744C202.689 18.8464 202.983 17.9924 202.983 16.9144V9.14844H205.31V16.9874C205.31 18.7354 204.808 20.1074 203.803 21.1034C202.798 22.0994 201.423 22.5974 199.675 22.5974Z"
                fill="#0E5AA9"
            />
            <path
                d="M217.706 11.0581V9.14712H219.96V22.2781H217.706V20.4161C216.53 21.8701 214.987 22.5961 213.076 22.5961C211.181 22.5961 209.613 21.9271 208.372 20.5871C207.131 19.2481 206.511 17.5901 206.511 15.6141C206.511 13.6381 207.131 12.0211 208.372 10.7631C209.613 9.47313 211.182 8.82812 213.076 8.82812C214.937 8.82913 216.481 9.57213 217.706 11.0581ZM217.853 15.6891C217.853 14.3661 217.436 13.2561 216.603 12.3571C215.77 11.4421 214.684 10.9851 213.345 10.9851C212.005 10.9851 210.927 11.4431 210.111 12.3571C209.295 13.2711 208.886 14.3901 208.886 15.7131C208.886 17.0361 209.294 18.1551 210.111 19.0691C210.927 19.9841 212.006 20.4411 213.345 20.4411C214.684 20.4411 215.77 19.9841 216.603 19.0691C217.436 18.1711 217.853 17.0451 217.853 15.6891Z"
                fill="#0E5AA9"
            />
            <path
                d="M229.832 19.6822L230.714 21.4942C229.767 22.2292 228.599 22.5972 227.21 22.5972C225.839 22.5972 224.797 22.1732 224.087 21.3232C223.376 20.4742 223.022 19.2572 223.022 17.6732V11.1562H220.694V9.14719H223.047V4.49219H225.35V9.14719H230.004V11.1562H225.35V17.8442C225.35 19.5752 226.06 20.4412 227.481 20.4412C228.247 20.4422 229.031 20.1892 229.832 19.6822Z"
                fill="#0E5AA9"
            />
            <path
                d="M232.356 7.28803C231.866 7.28803 231.458 7.12803 231.13 6.81003C230.804 6.49203 230.641 6.08703 230.641 5.59803C230.641 5.12403 230.804 4.73203 231.13 4.42203C231.457 4.11203 231.865 3.95703 232.356 3.95703C232.813 3.95703 233.205 4.11603 233.532 4.43503C233.858 4.75403 234.022 5.14203 234.022 5.59903C234.022 6.08903 233.858 6.49303 233.532 6.81103C233.205 7.12803 232.813 7.28803 232.356 7.28803ZM233.483 9.15003V22.281H231.13V9.15003H233.483Z"
                fill="#0E5AA9"
            />
            <path
                d="M241.763 22.5967C239.754 22.5967 238.096 21.9357 236.789 20.6127C235.499 19.2737 234.854 17.6327 234.854 15.6887C234.854 13.7457 235.499 12.1207 236.789 10.8137C238.079 9.47469 239.737 8.80469 241.763 8.80469C243.755 8.80469 245.397 9.47469 246.688 10.8137C247.994 12.1687 248.648 13.7937 248.648 15.6887C248.648 17.6167 247.994 19.2577 246.688 20.6127C245.381 21.9357 243.739 22.5967 241.763 22.5967ZM246.296 15.6887C246.296 14.3987 245.856 13.2957 244.973 12.3817C244.091 11.4507 243.022 10.9857 241.764 10.9857C240.506 10.9857 239.437 11.4507 238.555 12.3817C237.673 13.2967 237.232 14.3987 237.232 15.6887C237.232 16.9957 237.673 18.1137 238.555 19.0447C239.437 19.9757 240.506 20.4407 241.764 20.4407C243.022 20.4407 244.091 19.9757 244.973 19.0447C245.855 18.1137 246.296 16.9957 246.296 15.6887Z"
                fill="#0E5AA9"
            />
            <path
                d="M252.347 22.2787H249.994V9.14769H252.298V11.0097C253.131 9.53969 254.519 8.80469 256.462 8.80469C257.933 8.80469 259.165 9.27869 260.162 10.2257C261.157 11.1737 261.656 12.5377 261.656 14.3167V22.2787H259.329V14.9777C259.329 13.7207 259.022 12.7407 258.41 12.0377C257.798 11.3357 256.984 10.9847 255.972 10.9847C254.91 10.9847 254.036 11.3607 253.35 12.1117C252.68 12.8797 252.346 13.8427 252.346 15.0027V22.2787H252.347Z"
                fill="#0E5AA9"
            />
            <path
                d="M18.4523 7.57066C17.8133 7.29566 17.1953 7.03066 16.5413 6.74966C17.5493 6.23966 18.5523 5.73266 19.5553 5.22566V1.30666C19.5553 0.776656 19.1263 0.347656 18.5963 0.347656H10.0283V19.2837C12.8423 15.3707 15.6423 11.4787 18.4523 7.57066Z"
                fill="#0E5AA9"
            />
            <path
                d="M6.12395 16.1507V0.347656H5.37795C4.84795 0.347656 4.41895 0.776656 4.41895 1.30666V14.6307C4.81795 14.9457 5.20595 15.2777 5.58195 15.6267C5.76695 15.7977 5.94695 15.9727 6.12395 16.1507Z"
                fill="#0E5AA9"
            />
            <path
                d="M9.18602 20.4557C9.46702 20.0647 9.74802 19.6737 10.029 19.2837V0.347656H6.12402V16.1507C7.36702 17.4037 8.39702 18.8227 9.18602 20.4557Z"
                fill="white"
            />
            <path
                d="M23.9146 3.02344C22.4556 3.76144 21.0066 4.49344 19.5566 5.22744C18.5546 5.73444 17.5506 6.24144 16.5426 6.75144C17.1966 7.03244 17.8146 7.29844 18.4536 7.57244C15.6426 11.4804 12.8436 15.3734 10.0296 19.2854C9.74859 19.6764 9.46759 20.0664 9.18659 20.4574C8.39759 18.8244 7.36759 17.4054 6.12359 16.1514C5.94759 15.9734 5.76659 15.7994 5.58159 15.6274C5.20559 15.2784 4.81759 14.9474 4.41859 14.6314C4.07359 14.3584 3.72059 14.0974 3.35859 13.8494C2.48959 13.2544 1.57059 12.7324 0.600586 12.2884C1.61659 13.1934 2.53359 14.1544 3.35859 15.1674C3.73059 15.6244 4.08359 16.0914 4.41859 16.5684C5.04859 17.4644 5.61459 18.3974 6.12359 19.3624C6.83659 20.7164 7.43659 22.1354 7.93459 23.6134C8.07159 24.0184 8.19959 24.4284 8.32159 24.8414C8.49959 25.4454 8.66259 26.0584 8.80959 26.6794C8.84659 26.6364 8.87959 26.6004 8.91059 26.5624C9.28359 26.1074 9.65659 25.6514 10.0286 25.1964C10.1256 25.0784 10.2226 24.9594 10.3196 24.8414C10.6546 24.4324 10.9896 24.0224 11.3256 23.6134C14.0696 20.2624 16.8126 16.9114 19.5566 13.5614C19.6996 13.3864 19.8426 13.2114 19.9856 13.0364C20.8566 11.9724 21.7296 10.9104 22.5956 9.84244C22.6836 9.73444 22.7466 9.72544 22.8636 9.79044C23.1636 9.95544 23.4706 10.1074 23.7746 10.2634C23.9226 10.3394 24.0716 10.4144 24.2456 10.5024C24.1346 8.00244 24.0256 5.52844 23.9146 3.02344Z"
                fill="url(#paint0_linear_132_136)"
            />
            <path
                opacity="0.26"
                d="M20.0783 12.0247L17.1893 15.9147C17.2983 15.7807 20.2973 11.7567 20.4063 11.6237C21.0723 10.8107 21.7393 9.99866 22.4013 9.18366C22.4683 9.10066 22.5163 9.09466 22.6063 9.14366C22.8353 9.26966 23.0703 9.38566 23.3023 9.50566C23.4153 9.56366 23.5293 9.62166 23.6623 9.68866C23.5773 7.77666 23.4943 5.88666 23.4093 3.97266C22.2943 4.53666 21.1873 5.09566 20.0793 5.65666C19.3133 6.04366 18.5463 6.43166 17.7763 6.82066C18.2763 7.03566 18.7483 7.23866 19.2363 7.44766L13.7793 15.1497"
                fill="url(#paint1_linear_132_136)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_132_136"
                    x1="9.30659"
                    y1="32.6643"
                    x2="13.1882"
                    y2="6.17714"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#009DC5" />
                    <stop offset="1" stopColor="#00BDEE" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_132_136"
                    x1="13.041"
                    y1="18.2098"
                    x2="22.4939"
                    y2="5.95166"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.4424" stopColor="white" stopOpacity="0" />
                    <stop offset="0.9989" stopColor="white" />
                </linearGradient>
            </defs>
        </svg>
    );
}
